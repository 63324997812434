<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:add_fail>
        <CreateFormLayout>
          <template v-slot:title__text>
            Массовое добавление пользователей
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <div class="instruction">
            <p class="instruction__title">Правила заполнения файла:</p>
            <div class="instruction__description">
              <ol>
                <li>
                  Каждая строка должна содержать номер телефона и поля с
                  дополнительной информацией: пользователь, дом/корпус,
                  квартира/офис, номер авто, комментарий. Всего 6 полей.
                </li>
                <li>
                  Номер телефона должен соответствовать формату 79876543210. Не
                  должно быть пробелов, скобок и других знаков.
                </li>
                <li>
                  Поля с дополнительной информацией являются текстовыми.
                  Максимальная длина 40 символов.
                </li>
                <li>Не допускаются пустые строки.</li>
                <li>
                  Пример заполнения
                  <a href="/img/images/documentations/upload_user_object.png"
                    >по ссылке.</a
                  >
                </li>
              </ol>
            </div>
          </div>

          <template>
            <!--    Данные компании    -->
            <div>
              <FormInputBlock>
                <ValidationFileField
                  v-model="file"
                  :rules="'required'"
                  accept=".xlsx"
                  label="Выбрать файл"
                  validate-name="выбрать файл"
                />
                <ValidationAutocompleteField
                  rules="required"
                  :search-function="groupSearch"
                  label="Группа пользователей"
                  v-model="group_file"
                  :disabled="!!userGroupsId"
                />
              </FormInputBlock>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(uploadUsers)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>

      <template v-slot:manual_input>
        <CreateFormLayout>
          <template v-slot:title__text>
            Добавление пользователя
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <template>
            <!-- выбор пользователя -->
            <!--        <div v-if="userGroupsId">-->

            <div>
              <FormInputBlock>
                <ValidationAutocompleteField
                  rules="required"
                  :search-function="groupSearch"
                  label="Группа пользователей"
                  v-model="group"
                  :disabled="!!userGroupsId"
                />
              </FormInputBlock>
            </div>

            <!--    Данные компании    -->
            <div>
              <FormCardTitle title="Личные данные"></FormCardTitle>

              <FormInputBlock>
                <ValidationInputField
                  label="Телефон"
                  v-model="phone_number"
                  mask="+7 (###) ###-##-##"
                  rules="required|all_phone"
                  validate-name="Телефон"
                />
                <ValidationInputField
                  label="Пользователь (необязательно)"
                  validate-name="пользователь"
                  :rules="'min:1|max:40'"
                  v-model="user"
                  :disabled="!disabledEdit"
                />
                <ValidationInputField
                  label="Дом/корпус (необязательно)"
                  validate-name="дом/корпус"
                  :rules="'min:1|max:40'"
                  :disabled="!disabledEdit"
                  v-model="house"
                />
                <ValidationInputField
                  label="Квартира/офис (необязательно)"
                  validate-name="квартира/офис"
                  :rules="'min:1|max:40'"
                  :disabled="!disabledEdit"
                  v-model="houseNumber"
                />
                <ValidationInputField
                  label="Номер авто (необязательно)"
                  validate-name="номер авто"
                  :rules="'min:1|max:40'"
                  :disabled="!disabledEdit"
                  v-model="carNumber"
                />

                <ValidationInputField
                  label="Комментарий (необязательно)"
                  validate-name="комментарий"
                  :rules="'min:1|max:40'"
                  :disabled="!disabledEdit"
                  v-model="comments"
                />
              </FormInputBlock>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(createUser)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>
    </SlideLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import { getAccessGroupsRequest } from "@/helpers/api/access-group";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";

import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CreateUserPageFromObjects",
  data() {
    return {
      loading: false,
      value: null,
      number: null,
      groupNumber: null,
      currentUser: null,
      disabledEdit: false,

      groupSearch: async value => {
        const query = {
          object: this.objectId || "",
          limit: 1000
        };

        if (typeof value === "string") {
          query.search = value;
        } else {
          query.ids = value;
        }

        const result = (
          await getAccessGroupsRequest({ query })
        ).data.results.map(el => ({ text: el.title, value: el.id }));

        return [20, 21, 31].includes(this.currentRole)
          ? result.filter(
              el => !el.text.includes("Пользователи с бесплатным доступом")
            )
          : result;
      },
      slideTabs: [
        {
          text: "Добавить вручную",
          value: "manual_input",
          icon: null,
          count: null
        },
        {
          text: "Загрузить файл xlsx",
          value: "add_fail",
          icon: null,
          count: null
        }
      ]
    };
  },
  props: {
    companyId: {
      type: [String, Number]
    },
    objectId: {
      type: [Number, String]
    },
    kindergartenId: {
      type: [Number, String]
    },
    userGroupsId: {
      type: [Number, String]
    }
  },
  components: {
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    CreateFormLayout,
    MainLayout,
    SlideLayout,
    ValidationFileField,
    FormCardTitle
  },
  watch: {
    phone_number: async function(val) {
      let data = {
        phone_number: this.phone_number
      };
      if (val?.length == 18) {
        this.$store.dispatch("fetchObjectUserInfo", { data }).then(() => {
          this.currentUser = this.$store.getters.getCreateCurrentUserObjects;
          this.dadataField();
        });
      }
    }
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    currentOrganization() {
      return this.$store.getters.getCurrentOrganization;
    },
    correctOrganizationId() {
      return [60, 70].includes(this.currentRole)
        ? ""
        : this.currentOrganization;
    },
    file: {
      get() {
        return this.$store.getters.getCreateUserFileForm.file;
      },
      set(newValue) {
        this.$store.commit("setCreateUserFileForm", {
          fieldName: "file",
          value: newValue
        });
      }
    },
    group_file: {
      get() {
        return this.$store.getters.getCreateUserFileForm.group;
      },
      set(newValue) {
        this.$store.commit("setCreateUserFileForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    phone_number: {
      get() {
        return this.$store.getters.getCreateUserOnObjectForm.phone_number;
      },
      set(newValue) {
        this.number = newValue;
        this.$store.commit("setCreateUserObjectForm", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    group: {
      get() {
        return this.$store.getters.getCreateUserOnObjectForm.group;
      },
      set(newValue) {
        this.groupNumber = newValue;
        this.$store.commit("setCreateUserObjectForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    user: {
      get() {
        return this.$store.getters.getCreateUserOnObjectForm.user;
      },
      set(newValue) {
        this.$store.commit("setCreateUserObjectForm", {
          fieldName: "user",
          value: newValue
        });
      }
    },
    house: {
      get() {
        return this.$store.getters.getCreateUserOnObjectForm.house;
      },
      set(newValue) {
        this.$store.commit("setCreateUserObjectForm", {
          fieldName: "house",
          value: newValue
        });
      }
    },
    houseNumber: {
      get() {
        return this.$store.getters.getCreateUserOnObjectForm.houseNumber;
      },
      set(newValue) {
        this.$store.commit("setCreateUserObjectForm", {
          fieldName: "houseNumber",
          value: newValue
        });
      }
    },
    carNumber: {
      get() {
        return this.$store.getters.getCreateUserOnObjectForm.carNumber;
      },
      set(newValue) {
        this.$store.commit("setCreateUserObjectForm", {
          fieldName: "carNumber",
          value: newValue
        });
      }
    },
    comments: {
      get() {
        return this.$store.getters.getCreateUserOnObjectForm.comments;
      },
      set(newValue) {
        this.$store.commit("setCreateUserObjectForm", {
          fieldName: "comments",
          value: newValue
        });
      }
    }
  },
  mounted() {
    if (this.userGroupsId) {
      this.group = Number(this.userGroupsId);
      this.group_file = Number(this.userGroupsId);
    }
  },
  methods: {
    dadataField() {
      if (this.currentUser.length == 0) {
        this.$store.commit("clearCreateUserObjectForm");
        this.phone_number = this.number;
        this.group = this.groupNumber;
        this.disabledEdit = true;
      } else {
        this.disabledEdit = false;
        this.user = this.currentUser[0].additional_fields[0].value;
        this.house = this.currentUser[0].additional_fields[1].value;
        this.houseNumber = this.currentUser[0].additional_fields[2].value;
        this.carNumber = this.currentUser[0].additional_fields[3].value;
        this.comments = this.currentUser[0].additional_fields[4].value;
      }
    },
    createUser() {
      this.loading = true;
      this.$store
        .dispatch("createUserOnObject")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.user)
          );
        })
        .finally(() => (this.loading = false));
    },
    uploadUsers() {
      this.loading = true;
      this.$store
        .dispatch("uploadUsersObject")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.upload(successMessageDictionary.users)
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearCreateUserObjectForm");
    next();
  }
};
</script>

<style scoped lang="scss">
.instruction {
  padding: 29px 28px 30px;
  background-color: #fff;
  position: relative;

  .instruction__title {
    font-size: 16px;
    line-height: 20px;
    color: #515a6e;
    font-family: Inter, sans-serif;
    font-weight: 700;
  }

  .instruction__description {
    padding-top: 10px;
  }
}
</style>
